<template>
	<div class="account">
		<div class="wrapper">
			<div class="screen">
				<el-breadcrumb separator-class="el-icon-arrow-right">
					<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
					<el-breadcrumb-item>销售信息管理</el-breadcrumb-item>
				</el-breadcrumb>
				<el-form :inline="true" class="demo-form-inline">
					<el-form-item>
						<el-input v-model="query.keyword" placeholder="请输入查询关键字"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="onSubmit">查询</el-button>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="addClick">添加</el-button>
					</el-form-item>
				</el-form>
			</div>
			<el-table :data="tableData" class="main-table" header-row-class-name="table-header"
				cell-class-name="table-hover" :row-class-name="tableRowClassName" @sort-change="handleSort"
				:default-sort="{prop: 'modifyTime', order: 'descending'}">
				<el-table-column label="序号" type="index" :index="indexMethod" width="80" align="center">
				</el-table-column>
				<el-table-column label="车辆vin" prop="vin" align="center">
				</el-table-column>
                <el-table-column label="查询码" prop="code" align="center">
                </el-table-column>
                <el-table-column label="客户名称" prop="customerName" align="center">
                </el-table-column>
                <el-table-column label="零件名称" prop="productName" align="center">
                </el-table-column>
                <el-table-column label="零件号码" prop="productCode" align="center">
                </el-table-column>
                <el-table-column label="购买时间" prop="buyDate" align="center">
                </el-table-column>
                <el-table-column label="销售车型码" prop="other1" align="center">
                </el-table-column>
                <el-table-column label="品牌信息" prop="other2" align="center">
                </el-table-column>
                <el-table-column label="备注" prop="other3" align="center">
                </el-table-column>
                <el-table-column label="最后查询时间" prop="lastQueryTime" align="center">
                </el-table-column>
				<el-table-column label="操作" width="300" align="center" class-name="table-operate">
					<template slot-scope="{row}">
						<el-button v-if="row.status != 1" class="operation-btn" size="small" title="修改" type="primary"
							@click="editClick(row)">修改</el-button>
                        <el-button v-if="row.status != 1 && power_product_sale" class="operation-btn" size="small" title="审核" type="primary"
                                   @click="checkClick(row)">审核</el-button>
                        <el-tag v-if="row.status == 1" type="warning">已审核</el-tag>
                        <el-button v-if="row.status == 1 && power_product_sale" class="operation-btn" size="small" title="审核" type="primary"
                                   @click="unCheckClick(row)">反审核</el-button>
						<el-button v-if="row.status != 1" type="danger" size="small" title="删除" @click="deleteClick(row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<el-pagination background layout="prev, pager, next" :total="this.total" :current-page.sync="query.page"
				:page-size="query.pagesize" @current-change="getList">
			</el-pagination>
		</div>
	</div>
</template>

<script>
import {fetchList, delProductSale, checkProductSale, unCheckProductSale} from "../../api/productSale";
    import power from "../../power";

    export default {
		components: {},
		data() {
			return {
				tableData: [],
				query: {
                    vin: '',
                    customerName: '',
					page: 1,
					pagesize: 10,
				},
				total: '',
                power_product_sale: false
			};
		},
		created() {
            this.power_product_sale = power('productSaleCheck');
			this.getList();
		},
		methods: {
			onSubmit() {
                this.query.page = 1
                this.getList()
			},
			handleVisiable(e) {
				if (e.target.visibilityState === 'visible') {
					this.getList()
				}
			},
			indexMethod(e) {
				return (this.query.page - 1) * this.query.pagesize + e + 1
			},
			tableRowClassName({
				rowIndex
			}) {
				if (rowIndex % 2 == 0) {
					return "";
				} else {
					return "active-row";
				}
			},
			getList() {
                fetchList(this.query).then(res => {
                    this.tableData = res.data.list
                    this.total = res.data.total
                })
			},
            addClick() {
                this.$router.push("/productSaleDetail")
            },
			editClick(row) {
                this.$router.push({
                    name: 'productSaleDetail',
                    params: {
                        data: JSON.stringify(row)
                    }
                })
			},
            deleteClick(row) {
                this.$confirm("确定要永久删除该记录吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        delProductSale({ id: row.id }).then(() => {
                            this.$message({
                                type: "success",
                                message: "删除成功!",
                            });
                            this.getList()
                        })
                    })
            },
            checkClick(row) {
                this.$confirm("确定要审核通过此记录吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        checkProductSale({ id: row.id }).then(() => {
                            this.$message({
                                type: "success",
                                message: "审核成功!",
                            });
                            this.getList()
                        })
                    })
            },
            unCheckClick(row) {
                this.$confirm("确定要反审核此记录吗?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        unCheckProductSale({ id: row.id }).then(() => {
                            this.$message({
                                type: "success",
                                message: "反审核成功!",
                            });
                            this.getList()
                        })
                    })
            },
			handleSort(e) {
                this.query.sortProp = e.prop
                this.query.sortOrder = e.order
                this.query.page = 1
                this.getList()
			},
		},
	};
</script>

<style lang="scss">
	@import '~@/assets/css/account.scss';
</style>
