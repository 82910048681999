import request from '@/utils/request'

export function fetchList(query) {
    return request({
        url: '/admin/productSale/list',
        method: 'get',
        params: query
    })
}

export function saveProductSale(data) {
    return request({
        url: '/admin/productSale',
        method: 'post',
        data
    })
}

export function delProductSale(data) {
    return request({
        url: '/admin/productSale/del',
        method: 'post',
        data
    })
}

export function checkProductSale(data) {
    return request({
        url: '/admin/productSale/check',
        method: 'post',
        data
    })
}

export function unCheckProductSale(data) {
    return request({
        url: '/admin/productSale/unCheck',
        method: 'post',
        data
    })
}
